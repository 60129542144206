<template>
  <div class="container">
    <!--  tab切换-->
    <tabsCom
      :tabsNumber.sync="tabsNumber"
      :list="tabsList.needOrder"
      @handleClick="handleClick"
    />
    <!--  表格-->
    <div class="order-list" v-if="data.length">
      <div
        class="order-item"
        style="padding: 20px; margin-bottom: 20px; box-shadow: 0 2px 4px #ccc"
        v-for="(item, index) of data"
        :key="index"
      >
        <div style="line-height: 40px">
          <span style="margin-right: 40px">订单号：{{ item[0].orderNo }}</span>
          下单人：{{ item[0].userName }}
          <span style="margin-left: 40px">
            联系电话：{{ item[0].deliveryPhone || "无" }}</span
          >
        </div>
        <div style="line-height: 40px">
          <span style="margin-right: 40px"
            >下单时间：{{ item[0].placeTime }}</span
          >
          <span style="margin-right: 40px" v-if="deftab == 2"
            >取消时间：{{ item[0].cancelTime }}</span
          >
        </div>
        <orderList
          :data.sync="item"
          :header="tableList.needHeader"
          @delivery="delivery"
          @orderDetail="orderDetailFlybox"
          @orderCalcel="orderCalcel"
          @confirm="confirm"
        />
      </div>
    </div>
    <listNull v-else text="这里什么也没有~" />
    <div v-if="total > 10" style="padding: 30px 0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageIndex"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="订单详情"
      :visible.sync="showOrderDetail"
      width="60%"
      :before-close="handleClose"
    >
      <div class="detailFlybox">
        <el-divider content-position="left">基本信息</el-divider>
        <div class="order-list">
          <div class="order-item">订单号：{{ orderDetail[0].orderNo }}</div>
          <div class="order-item">
            支付方式：{{
              orderDetail[0].payType == ""
                ? "未支付"
                : orderDetail[0].payType == 1
                ? "微信支付"
                : "支付宝"
            }}
          </div>
          <div class="order-item">下单人：{{ orderDetail[0].userName }}</div>
          <div class="order-item">联系电话：{{ orderDetail[0].userPhone }}</div>
          <div class="order-item">
            配送方式：快递服务 <span style="margin-left: 20px"></span>收货人：{{
              orderDetail[0].consignee
            }}
            <span style="margin-left: 20px"></span>收货人手机：{{
              orderDetail[0].contactNumber
            }}
          </div>
          <div class="order-item">
            收货地址：{{ orderDetail[0].deliveryAddress }}
          </div>
          <div class="order-item">
            订单备注：{{ orderDetail[0].orderRemark }}
          </div>
          <!--<div class="order-item">发票：<a v-if="orderDetail[0].invoiceTitle" :href="orderDetail[0].invoiceTitle" target="_blank" style="cursor: pointer" download>点击预览发票文件</a><span v-else>无</span></div>-->
          <div class="order-item">下单时间：{{ orderDetail[0].placeTime }}</div>
        </div>
        <orderList
          :data.sync="orderDetail"
          :header="tableList.needHeader2"
          @delivery="delivery"
          @orderCalcel="orderCalcel"
          @confirm="confirm"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { toBeConfirmedList, updateReceivingOrder, updateOrderState } from '@/api/order.js'
import { needOrderForm, queryOrderForm, tabsList, tableList } from '../js/orderManage.js'

import { printrecevingqueryToBeConfirmPage, printrecevingqueryCancelPage, printrecevingcancel, printrecevingconfirm } from '@/api/cubeclient.js'
import tabsCom from '../components/tabsCom.vue'
import orderList from '../components/orderList.vue'
import listNull from '@/components/listNull.vue'
export default {
  name: 'orderConfirm',
  components: { tabsCom, orderList, listNull },
  data () {
    return {
      isQuery: false,
      showOrderDetail: false, // 显示订单详情弹窗
      orderDetail: [{}], // 订单详情
      needOrderForm: needOrderForm,
      queryOrderForm: queryOrderForm,
      tabsList: tabsList,
      tableList: tableList,
      data: [],
      tabsNumber: {},
      form: {},
      deftab: '0',
      pageIndex: 1,
      limit: 10,
      total: 0
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    handleSizeChange (val) {
      this.limit = val
      this.getData()
    },
    handleCurrentChange (val) {
      this.pageIndex = val
      this.getData()
    },
    handleClick (name) {
      this.deftab = name
      this.data = []
      this.getData()
    },
    async getData () {
      this.data = []
      // printrecevingqueryToBeConfirmPage
      console.log(this.deftab)
      if (parseInt(this.deftab) === 0) {
        let res = await printrecevingqueryToBeConfirmPage({
          "pageNo": this.pageIndex,
          "pageSize": this.limit
        })
        if (res.code === 200) {
          this.total = res.data.total
          if (res.data.list.length > 0) {
            for (const i of res.data.list) {
              const arr = []
              arr.push(i)
              this.data.push(arr)
            }
          } else {
            this.data = []
          }
        }
      } else if (parseInt(this.deftab) === 2) {
        let res = await printrecevingqueryCancelPage({
          "pageNo": this.pageIndex,
          "pageSize": this.limit
        })
        if (res.code === 200) {
          this.total = res.data.total
          if (res.data.list.length > 0) {
            for (const i of res.data.list) {
              const arr = []
              arr.push(i)
              this.data.push(arr)
            }
          } else {
            this.data = []
          }
        }
      }
      // toBeConfirmedList({
      //   stauts: parseInt(this.deftab),
      //   page: this.pageIndex,
      //   limit: this.limit
      // }).then(res => {
      //   if (res.code === 0) {
      //     this.total = res.data.total
      //     if (res.data.list.length > 0) {
      //       for (const i of res.data.list) {
      //         const arr = []
      //         arr.push(i)
      //         this.data.push(arr)
      //       }
      //       console.log(this.data)
      //     } else {
      //       this.data = []
      //     }
      //   } else {
      //     this.$message({
      //       type: 'error',
      //       message: res.msg
      //     })
      //   }
      // })
    },
    confirm (row, arg) { // 确认订单
      printrecevingconfirm({
        orderNo: row.orderNo
      }).then(res => {
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '确认订单成功'
          })
          this.getData()
          this.showOrderDetail = false
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    orderCalcel (row, arg) { // 取消订单
      this.$confirm('此操作不可撤销, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        printrecevingcancel({

          orderNo: row.orderNo
        }).then(res => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '取消订单成功'
            })
            this.getData()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    delivery (row) { // 发货
      updateOrderState({
        orderCode: row.orderCode,
        orderStatus: '5',
        invoiceStatus: '',
        invoiceTitle: '',
        logisticsSerialNumber: ''
      }).then(res => {
        if (res.code === 0) {
          this.$message({
            type: 'success',
            message: '发货成功'
          })
          this.getData()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    orderDetailFlybox (row) { // 订单详情
      console.log(row)
      this.orderDetail = [row]
      this.showOrderDetail = true
    },
    handleClose () {
      this.showOrderDetail = false
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  flex-direction: column;
  margin: 20px 30px;
  .detailFlybox {
    .order-list {
      display: flex;
      flex-wrap: wrap;
    }
    .order-item {
      width: 50%;
      line-height: 30px;
    }
  }
}
</style>
