var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("tabsCom", {
        attrs: { tabsNumber: _vm.tabsNumber, list: _vm.tabsList.needOrder },
        on: {
          "update:tabsNumber": function($event) {
            _vm.tabsNumber = $event
          },
          "update:tabs-number": function($event) {
            _vm.tabsNumber = $event
          },
          handleClick: _vm.handleClick
        }
      }),
      _vm.data.length
        ? _c(
            "div",
            { staticClass: "order-list" },
            _vm._l(_vm.data, function(item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "order-item",
                  staticStyle: {
                    padding: "20px",
                    "margin-bottom": "20px",
                    "box-shadow": "0 2px 4px #ccc"
                  }
                },
                [
                  _c("div", { staticStyle: { "line-height": "40px" } }, [
                    _c("span", { staticStyle: { "margin-right": "40px" } }, [
                      _vm._v("订单号：" + _vm._s(item[0].orderNo))
                    ]),
                    _vm._v(" 下单人：" + _vm._s(item[0].userName) + " "),
                    _c("span", { staticStyle: { "margin-left": "40px" } }, [
                      _vm._v(
                        " 联系电话：" + _vm._s(item[0].deliveryPhone || "无")
                      )
                    ])
                  ]),
                  _c("div", { staticStyle: { "line-height": "40px" } }, [
                    _c("span", { staticStyle: { "margin-right": "40px" } }, [
                      _vm._v("下单时间：" + _vm._s(item[0].placeTime))
                    ]),
                    _vm.deftab == 2
                      ? _c(
                          "span",
                          { staticStyle: { "margin-right": "40px" } },
                          [_vm._v("取消时间：" + _vm._s(item[0].cancelTime))]
                        )
                      : _vm._e()
                  ]),
                  _c("orderList", {
                    attrs: { data: item, header: _vm.tableList.needHeader },
                    on: {
                      "update:data": function($event) {
                        item = $event
                      },
                      delivery: _vm.delivery,
                      orderDetail: _vm.orderDetailFlybox,
                      orderCalcel: _vm.orderCalcel,
                      confirm: _vm.confirm
                    }
                  })
                ],
                1
              )
            }),
            0
          )
        : _c("listNull", { attrs: { text: "这里什么也没有~" } }),
      _vm.total > 10
        ? _c(
            "div",
            { staticStyle: { padding: "30px 0" } },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pageIndex,
                  "page-sizes": [10, 20, 30, 40],
                  "page-size": _vm.limit,
                  layout: "total, sizes, prev, pager, next, jumper",
                  total: _vm.total
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "订单详情",
            visible: _vm.showOrderDetail,
            width: "60%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.showOrderDetail = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "detailFlybox" },
            [
              _c("el-divider", { attrs: { "content-position": "left" } }, [
                _vm._v("基本信息")
              ]),
              _c("div", { staticClass: "order-list" }, [
                _c("div", { staticClass: "order-item" }, [
                  _vm._v("订单号：" + _vm._s(_vm.orderDetail[0].orderNo))
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v(
                    " 支付方式：" +
                      _vm._s(
                        _vm.orderDetail[0].payType == ""
                          ? "未支付"
                          : _vm.orderDetail[0].payType == 1
                          ? "微信支付"
                          : "支付宝"
                      ) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v("下单人：" + _vm._s(_vm.orderDetail[0].userName))
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v("联系电话：" + _vm._s(_vm.orderDetail[0].userPhone))
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v(" 配送方式：快递服务 "),
                  _c("span", { staticStyle: { "margin-left": "20px" } }),
                  _vm._v(
                    "收货人：" + _vm._s(_vm.orderDetail[0].consignee) + " "
                  ),
                  _c("span", { staticStyle: { "margin-left": "20px" } }),
                  _vm._v(
                    "收货人手机：" +
                      _vm._s(_vm.orderDetail[0].contactNumber) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v(
                    " 收货地址：" +
                      _vm._s(_vm.orderDetail[0].deliveryAddress) +
                      " "
                  )
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v(
                    " 订单备注：" + _vm._s(_vm.orderDetail[0].orderRemark) + " "
                  )
                ]),
                _c("div", { staticClass: "order-item" }, [
                  _vm._v("下单时间：" + _vm._s(_vm.orderDetail[0].placeTime))
                ])
              ]),
              _c("orderList", {
                attrs: {
                  data: _vm.orderDetail,
                  header: _vm.tableList.needHeader2
                },
                on: {
                  "update:data": function($event) {
                    _vm.orderDetail = $event
                  },
                  delivery: _vm.delivery,
                  orderCalcel: _vm.orderCalcel,
                  confirm: _vm.confirm
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }